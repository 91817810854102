import React, { useEffect, useContext, useRef } from "react"
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalStateProvider"
import { evaluateCriteria } from "../utils/evaluateCriteria"

const GlobalEventListener = () => {
	const { addEvent, setShowExitIntentPopup } = useContext(GlobalDispatchContext)
	const { eventLog, utmParams, exitIntentPopupCriteria, exitIntentPopupContent } =
		useContext(GlobalStateContext)
	const timeoutRef = useRef(null)

	useEffect(() => {
		const handleClick = event => {
			const target = event.target.closest("[data-umami-event]")
			if (target) {
				const eventName = target.getAttribute("data-umami-event")
				addEvent({ eventName, timestamp: new Date().toISOString() })
			}
		}

		const handleMouseOut = event => {
			if (
				!event.relatedTarget &&
				!event.toElement &&
				evaluateCriteria(exitIntentPopupCriteria, eventLog, utmParams)
			) {
				if (exitIntentPopupContent) {
					timeoutRef.current = setTimeout(() => {
						setShowExitIntentPopup(true)
					}, 500) // Grace period
				}
			}
		}

		const handleMouseEnter = () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current)
				timeoutRef.current = null
			}
		}

		document.addEventListener("click", handleClick)
		document.addEventListener("mouseout", handleMouseOut)
		document.addEventListener("mouseenter", handleMouseEnter)

		return () => {
			document.removeEventListener("click", handleClick)
			document.removeEventListener("mouseout", handleMouseOut)
			document.removeEventListener("mouseenter", handleMouseEnter)
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current)
			}
		}
	}, [
		addEvent,
		setShowExitIntentPopup,
		eventLog,
		utmParams,
		exitIntentPopupCriteria,
		exitIntentPopupContent,
	])

	return null
}

export default GlobalEventListener
