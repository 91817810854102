module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://cloudfleetmanager.com","stripQueryString":true},
    },{
      plugin: require('../plugins/gatsby-source-slack-users/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"xoxp-2777457854-2777690014-2779700264-734b37","limit":500},
    },{
      plugin: require('../node_modules/@imgix/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"domain":"cloudfleetmanager.imgix.net","secureURLToken":"FFrzNdq9p4HEyjkG","sourceType":"webProxy","defaultImgixParams":{"auto":"format,compress","breakpoints":[576,768,1200,1380,1440,1920]},"fields":[{"nodeType":"mongodbBloghanseaticsoftMedia","rawURLKey":"url","fieldName":"imgixImage"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-background-image-es5/gatsby-browser.js'),
      options: {"plugins":[],"specialChars":"/:"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cloud Fleet Manager","short_name":"Cloud Fleet Manager","start_url":"/","background_color":"#F2F4F5","theme_color":"#50ADF1","display":"standalone","icon":"src/images/Cloud-Fleet-Manager-Favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cae166f4ebcd0924b645e81f941bd8d3"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
