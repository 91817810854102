import React, { createContext, useState } from "react"

export const GlobalStateContext = createContext()
export const GlobalDispatchContext = createContext()

const GlobalStateProvider = ({ children }) => {
	const [state, setState] = useState(() => {
		return {
			user: null,
			campaign: null,
			theme: "light",
			utmParams: {},
			eventLog: [],
			showExitIntentPopup: false,
			exitIntentPopupContent: null,
			exitIntentPopupCriteria: null,
		}
	})

	const addEvent = event => {
		setState(prevState => ({
			...prevState,
			eventLog: [...prevState.eventLog, event],
		}))
	}

	const setShowExitIntentPopup = value => {
		setState(prevState => ({
			...prevState,
			showExitIntentPopup: value,
		}))
	}

	const setExitIntentPopupContent = content => {
		setState(prevState => ({
			...prevState,
			exitIntentPopupContent: content,
		}))
	}

	const setExitIntentPopupCriteria = criteria => {
		setState(prevState => ({
			...prevState,
			exitIntentPopupCriteria: criteria,
		}))
	}

	return (
		<GlobalStateContext.Provider value={state}>
			<GlobalDispatchContext.Provider
				value={{
					setState,
					addEvent,
					setShowExitIntentPopup,
					setExitIntentPopupContent,
					setExitIntentPopupCriteria,
				}}>
				{children}
			</GlobalDispatchContext.Provider>
		</GlobalStateContext.Provider>
	)
}

export default GlobalStateProvider
