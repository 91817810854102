import React, { createContext, useState } from "react"

const BannerVisibilityContext = createContext()

export const BannerVisibilityProvider = ({ children }) => {
	const [isBannerVisible, setIsBannerVisible] = useState(true)

	return (
		<BannerVisibilityContext.Provider value={{ isBannerVisible, setIsBannerVisible }}>
			{children}
		</BannerVisibilityContext.Provider>
	)
}

export default BannerVisibilityContext
