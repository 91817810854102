// src/utils/evaluateCriteria.js
export const evaluateCriteria = (criteria, eventLog, utmParams) => {
	if (!criteria || Object.keys(criteria).length === 0) return true // No criteria means always true

	const evaluateCondition = condition => {
		let result = false
		if (condition.type === "event") {
			result = eventLog.some(e => e.eventName === condition.value)
		} else if (condition.type === "utm") {
			result = utmParams[condition.key] === condition.value // Check against the specified UTM key
		}
		if (condition.negate) {
			return !result
		}
		return result
	}

	const evaluateLogic = logic => {
		if (!logic.conditions) {
			// Single condition
			return evaluateCondition(logic)
		}
		if (logic.operator === "AND") {
			return logic.conditions.every(evaluateCondition)
		}
		if (logic.operator === "OR") {
			return logic.conditions.some(evaluateCondition)
		}
		return false
	}

	return evaluateLogic(criteria)
}
